// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "style.css";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

document.addEventListener("turbolinks:load", function () {
    const form = document.getElementById("pnpkiForm");
    form.addEventListener("submit", function (e) {
        e.preventDefault();
        if (
            window.confirm(`
        Are you sure with the details you provided?
        Once you accept, you will no longer be able to edit the form.
        `)
        ) {
            e.target.submit();
        }
    });
});
